// 创建企业
// mxy
<template>
  <div class="main" :style="{ minHeight: minHeight + 'px' }">
    <a-form
      :model="register"
      ref="formRef"
      :rules="rules"
      autocomplete="off"
      :labelCol="{ span: 4 }"
      :wrapperCol="{ span: 20 }"
    >
      <a-form-item
        required
        name="name"
        label="企业名称"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-input
          style="width: 40%"
          size="large"
          :maxLength="30"
          type="text"
          v-model:value="register.name"
          placeholder="请输入"
        >
        </a-input>
      </a-form-item>
      <a-form-item
        label="所属行业"
        required
        name="industry"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-select
          style="width: 40%"
          placeholder="所属行业"
          size="large"
          v-model:value="register.industry"
          @change="handleChange"
        >
          <a-select-option
            v-for="r in externalGenreList"
            :key="r.linkKey"
            :value="r.linkKey"
            >{{ r.name }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="地址" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-cascader
          style="width: 40%"
          :options="CityInfo"
          placeholder="请选择"
          size="large"
          v-model:value="register.selectedOptions"
          @change="onChange"
        />
      </a-form-item>
      <a-form-item
        label="详细地址"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-input
          style="width: 40%"
          size="large"
          :maxLength="120"
          type="text"
          v-model:value="register.address"
          placeholder="请输入"
        >
        </a-input>
      </a-form-item>
      <a-form-item>
        <div class="flex-row operation">
          <a-button
            class="btn"
            @click="createOK"
            :loading="state.loginBtn"
            type="primary"
            >创建</a-button
          >
          <a-button @click="cancel" class="btn">重置</a-button>
        </div>
      </a-form-item>
    </a-form>
  </div>
  <a-modal
    :visible="visible"
    :confirm-loading="confirmLoading"
    ok-text="确定"
    cancel-text="取消"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <p>是否需要跳转新的企业</p>
  </a-modal>
  <a-modal
    :visible="errorVisible"
    :confirm-loading="confirmLoading"
    ok-text="确定"
    cancel-text="取消"
    :maskClosable="false"
    @ok="errorHandleOk"
    @cancel="errorHandleCancel"
  >
    <p>当前企业尚未认证，认证后方可创建新企业</p>
    <p>是否立即跳转认证页面？</p>
  </a-modal>
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'
import { minheight } from '../../components/sameVariable'
import axios from 'axios'

export default defineComponent({
  data () {
    return {
      confirmLoading: false,
      visible: false,
      errorVisible: false,
      CityInfo: [], // 地区数据
      spinning: false, // 是否加载
      selectedOptions: '',
      rules: {
        name: [
          {
            required: true,
            message: '请输入公司名称',
            trigger: 'blur',
          },
        ],
        industry: [
          {
            required: true,
            message: '请选择所属行业',
            trigger: 'blur',
          },
        ],
      }, // 验证规则
      labelCol: {
        span: 3,
      },
      wrapperCol: {
        span: 20,
      },
      externalGenreList: [],
      register: {
        name: '',
        address: '',
        industry: '',
        city: '',
        erae: '',
        minerae: '',
        selectedOptions: [],
      },
      state: {
        loginBtn: false,
        loginType: 0,
        smsSendBtn: false,
      },
      industryId: '',
      industryIdName: '',
      location: [],
      locationAddress: '',
      LocationIndex: '',
      tenantId: '',
    }
  },
  created () {
    this.minHeight = minheight
  },
  mounted () {
    this.handleFocus()
    axios.get('/api/city.json').then(data => {
      this.CityInfo = data.data
    })
  },
  methods: {
    onChange (value, selectedOptions) {
      this.location = []
      this.LocationIndex = value.toString()
      selectedOptions.forEach(element => {
        this.location.push(element.label)
        // this.locationAddress = (this.location.toString()).replace(/,/g, '-')
        this.locationAddress = this.location.toString().replace(/,/g, '')
      })

      this.register.city = this.register.selectedOptions[0]
      this.register.erae = this.register.selectedOptions[1]
      this.register.minerae = this.register.selectedOptions[2]
    },
    // 点击选择所属行业
    handleChange (value, option) {
      this.industryId = value
      option.children.forEach(element => {
        this.industryIdName = element.children
      })
    },
    // 获取所属单位数据
    handleFocus () {
      api
        .get('/api/app/sys-industry/industry-select-list')
        .then(({ data }) => {
          this.externalGenreList = data
        })
        .catch(err => {
          console.log(err)
          // this.$message.error('获取单位信息失败 ')
        })
    },
    // 重置
    cancel () {
      this.$refs.formRef.resetFields()
      this.register.selectedOptions = []
    },
    // 保存
    createOK () {
      this.$refs.formRef.validate().then(() => {
        this.state.loginBtn = true
        const obj = {
          name: this.register.name,
          location: this.locationAddress,
          LocationIndex: this.LocationIndex,
          industryId: this.industryId,
          industryName: this.industryIdName,
          detailAddress: this.register.address,
        }
        api
          .post('/api/app/account/tenant-enterprise-info', obj)
          .then(({ data }) => {
            this.tenantId = data
            this.state.loginBtn = false
            this.$message.success('创建成功！')
            this.visible = true
            this.cancel()
          })
          .catch(err => {
            this.state.loginBtn = false
            // console.log(err.response.data.error.message)
            if (err.response.data.error.message === '该账户当前仍有未认证的企业，认证后方可创建新企业') {
              this.errorVisible = true
            }
            console.log(err)
            // this.$message.error('创建失败' + err)
          })
      })
    },
    // 创建成功跳转新企业
    handleOk () {
      api
        .post('/api/app/account/switch-tenant/{' + this.tenantId + '}')
        .then(({ data }) => {
          this.$store.dispatch('account/setToken', data.rawData)
          this.$store.dispatch('account/setTenant', {
            tenantId: data.tenantId,
          })
          // location.reload()
          var url = window.location.href
          window.location.href = url
          window.location.href = '/WorkManagement/WorkBench'
        })
        .catch(err => {
          // this.$message.error('切换租户失败')
          console.log(err)
        })
    },
    errorHandleOk () {
      this.errorVisible = false
      this.$router.push({
        path: '/EnterpriseManagement/BasicInformation',
      })
    },
    handleCancel () {
      this.visible = false
    },
    errorHandleCancel () {
      this.errorVisible = false
    },
  },
  filters: {
    myAddressCity: function (value) {
      for (const y in this.CityInfo) {
        if (this.CityInfo[y].value === value) {
          value = this.CityInfo[y].label
          return value
        }
      }
    },
    myAddressErae: function (value) {
      for (const y in this.CityInfo) {
        for (const z in this.CityInfo[y].children) {
          if (this.CityInfo[y].children[z].value === value && value !== undefined) {
            value = this.CityInfo[y].children[z].label
            return value
          }
        }
      }
    },
    myAddressMinerae: function (value) {
      for (const y in this.CityInfo) {
        for (const z in this.CityInfo[y].children) {
          for (const i in this.CityInfo[y].children[z].children) {
            if (this.CityInfo[y].children[z].children[i].value === value && value !== undefined) {
              value = this.CityInfo[y].children[z].children[i].label
              return value
            }
          }
        }
      }
    },
  },
})
</script>

<style  lang="scss" scoped>
@import "@/assets/common.scss";
.main {
  background: #fff;
  .operation {
    width: 50%;
    margin-top: 10px;
    margin-left: 15%;
    .btn {
      margin-right: 40px;
    }
  }
}
.ant-cascader-menu {
  height: 160px !important;
}
</style>
